import {Params} from '@angular/router';

export function scroll(el: HTMLElement) {
  el.scrollIntoView({behavior: 'smooth'});
}

export function getParametersFromObject(object: { [p: string]: unknown } | null | undefined): URLSearchParams {
  const params = new URLSearchParams();
  if (!object) {
    return params;
  }
  Object.entries(object).forEach(([key, value]) => {
    if ((typeof value == 'boolean' || value) && value != 'undefined') {
      if (Array.isArray(value)) {
        value.forEach(value => params.append(key, value.toString()));
      } else {
        params.append(key, value.toString());
      }
    }
  });
  return params;
}

export function getParamsFromObject(object: { [p: string]: unknown }): Params {
  const params: Params = {}
  Object.entries(object).forEach(([key, value]) => {
    if (typeof value == "boolean" || value) {
      if (Array.isArray(value)) {
        params[key] = value.map(toString)
      } else {
        params[key] = value.toString()
      }
    }
  });
  return params
}

export function orThrow(errorMessage: string): never {
  throw new Error(errorMessage);
}

function words(liczba: number) {

  const jednosci = ["", " jeden", " dwa", " trzy", " cztery", " pięć", " sześć", " siedem", " osiem", " dziewięć"];
  const nascie = ["", " jedenaście", " dwanaście", " trzynaście", " czternaście", " piętnaście", " szesnaście", " siedemnaście", " osiemnaście", " dziewiętnaście"];
  const dziesiatki = ["", " dziesięć", " dwadzieścia", " trzydzieści", " czterdzieści", " pięćdziesiąt", " sześćdziesiąt", " siedemdziesiąt", " osiemdziesiąt", " dziewięćdziesiąt"];
  const setki = ["", " sto", " dwieście", " trzysta", " czterysta", " pięćset", " sześćset", " siedemset", " osiemset", " dziewięćset"];
  const grupy = [
    ["", "", ""],
    [" tysiąc", " tysiące", " tysięcy"],
    [" milion", " miliony", " milionów"],
    [" miliard", " miliardy", " miliardów"],
    [" bilion", " biliony", " bilionów"],
    [" biliard", " biliardy", " biliardów"],
    [" trylion", " tryliony", " trylionów"]];

  let wynik = '';
  let znak = '';
  if (liczba == 0)
    wynik = "zero";
  if (liczba < 0) {
    znak = "minus";
    liczba = -liczba;
  }

  let g = 0;
  while (liczba > 0) {
    const s = Math.floor((liczba % 1000) / 100);
    let n = 0;
    let d = Math.floor((liczba % 100) / 10);
    let j = Math.floor(liczba % 10);

    if (d == 1 && j > 0) {
      n = j;
      d = 0;
      j = 0;
    }

    let k = 2;
    if (j == 1 && s + d + n == 0)
      k = 0;
    if (j == 2 || j == 3 || j == 4)
      k = 1;
    if (s + d + n + j > 0)
      wynik = setki[s] + dziesiatki[d] + nascie[n] + jednosci[j] + grupy[g][k] + wynik;

    g++;
    liczba = Math.floor(liczba / 1000);
  }
  return znak + wynik;
}

export function numberToWords(number: number): string {
  const integer = Math.floor(number);
  const decimal = Math.round((number - integer) * 100);
  const integerWords = words(integer);
  const decimalWords = words(decimal);
  let integerEnd = 'złotych';
  let decimalEnd = 'groszy';
  if (integer % 10 == 1 && integer % 100 != 11) {
    integerEnd = 'złoty';
  } else if (integer % 10 >= 2 && integer % 10 <= 4 && (integer % 100 < 10 || integer % 100 >= 20)) {
    integerEnd = 'złote';
  }
  if (decimal == 1) {
    decimalEnd = 'grosz';
  } else if (decimal % 10 >= 2 && decimal % 10 <= 4 && (decimal % 100 < 10 || decimal % 100 >= 20)) {
    decimalEnd = 'grosze';
  }
  return `${integerWords} ${integerEnd} ${decimalWords} ${decimalEnd}`;
}

export function wordsEng(n: number) {
  if (n < 0)
    return false;

  const single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
  const double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
  const below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']

  if (n === 0) return 'Zero';

  function translate(n: number) {
    let word: string;
    if (n < 10) {
      word = single_digit[n] + ' ';
    } else if (n < 20) {
      word = double_digit[n - 10] + ' ';
    } else if (n < 100) {
      const rem = translate(n % 10);
      word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem;
    } else if (n < 1000) {
      word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100);
    } else if (n < 1000000) {
      word = translate(parseInt(String(n / 1000))).trim() + ' Thousand ' + translate(n % 1000);
    } else if (n < 1000000000) {
      word = translate(parseInt(String(n / 1000000))).trim() + ' Million ' + translate(n % 1000000);
    } else {
      word = translate(parseInt(String(n / 1000000000))).trim() + ' Billion ' + translate(n % 1000000000);
    }
    return word;
  }

  // Get the result by translating the given number
  const result = translate(n);
  return result.trim();
}

export function numberToWordsEng(number: number): string {
  const integer = Math.floor(number);
  const decimal = Math.round((number - integer) * 100);
  const integerWords = wordsEng(integer);
  const decimalWords = wordsEng(decimal);
  return `${integerWords} zł ${decimalWords} gr`;
}

export type Paths<T> = T extends object ? {
  [K in keyof T]:
  `${Exclude<K, symbol>}${"" | `.${Paths<T[K]>}`}`
}[keyof T] : never

export type Leaves<T> = T extends object ? {
  [K in keyof T]:
  `${Exclude<K, symbol>}${Leaves<T[K]> extends never ? "" : `.${Leaves<T[K]>}`}`
}[keyof T] : never
