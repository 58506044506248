import {AbstractBasicService} from './abstract-basic.service';
import {CrudService} from './crud-service';
import {Observable} from 'rxjs';
import {FilterType} from './basic-service';

export abstract class AbstractCrudService<T, ID, F extends FilterType> extends AbstractBasicService<T, ID, F> implements CrudService<T, ID, F>{
  update(t: T): Observable<T> {
    return this.http.put<T>(this.apiUrl, t, this.httpOptions);
  }

  create(t: T): Observable<T> {
    return this.http.post<T>(this.apiUrl, t, this.httpOptions);
  }

  delete(id: ID): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

}
