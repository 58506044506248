import {inject, Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {filter} from 'rxjs/operators';
import {BehaviorSubject, Observable, switchMap, tap} from 'rxjs';
import {Router} from '@angular/router';
import {UserDetails} from "../model/user/userDetails";
import {User} from "../model/user/user";
import {nonNull} from "../typeCheck/type-checks";
import * as Sentry from "@sentry/angular";
import {CacheService} from "./cache.service";
import {AbstractCrudService} from "./abstract-crud.service";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractCrudService<User, number, object> {

  private readonly userUrl = '/api/user';

  protected readonly apiUrl = '/api/users';

  private readonly router = inject(Router);

  private readonly cache = inject(CacheService);

  private userSubject: BehaviorSubject<UserDetails | null> = new BehaviorSubject<UserDetails | null>(null)
  $user: Observable<UserDetails> = this.userSubject.asObservable().pipe(
    filter(nonNull)
  );

  get user(): UserDetails | null {
    return this.userSubject.value;
  };

  init$() {
    return this.http.get<UserDetails>(this.userUrl, httpOptions).pipe(
      tap(user => {
        if (Object.keys(user).length !== 0) {
          this.setUser(user)
        }
      })
    );
  }

  setUser(user: UserDetails | null) {
    this.userSubject.next(user);
    const sentryUser = user ? {id: user.username, username: user.username, email: user.mail} : null;
    Sentry.setUser(sentryUser);
    this.cache.clear();
  }

  isAuthenticated() {
    return this.userSubject.value != null;
  }

  isEmployee() {
    return this.userSubject.value?.isEmployee ?? false;
  }

  isStudent() {
    return this.userSubject.value?.isStudent ?? false;
  }

  setUnauthenticated() {
    this.setUser(null);
  }

  hasRole(role: string): boolean {
    if (this.userSubject.value === undefined || this.userSubject.value?.authorities === undefined) {
      return false;
    }
    return this.userSubject.value.authorities.find((x) => x.authority === role) !== undefined
      || this.userSubject.value.authorities.find((x) => x.authority === 'ROLE_SUPER_ADMIN') !== undefined;
  }

  hasAnyRole(...roles: string[]): boolean {
    return roles.some(role => this.hasRole(role));
  }

  impersonate(username: string): Observable<UserDetails> {
    Sentry.setTag("impersonated_by", `${this.userSubject.value?.username}`)
    return this.http.post<void>(`${this.apiUrl}/impersonate?username=${username}`, httpOptions).pipe(
      switchMap(() => this.http.get<UserDetails>(this.userUrl, httpOptions)),
      tap(user => this.setUser(user)),
      tap(() => this.router.navigate(['/']))
    );
  }

  exitImpersonate() {
    Sentry.setTag("impersonated_by", undefined)
    return this.http.post<void>(`${this.apiUrl}/impersonate/exit`, httpOptions).pipe(
      switchMap(() => this.http.get<UserDetails>(this.userUrl, httpOptions)),
      tap(user => this.setUser(user)),
      tap(() => this.router.navigate(['/']))
    );
  }
}
