import {BasicService, FilterType} from './basic-service';
import {Observable} from 'rxjs';
import {Page} from '../model/dto/page/page';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {getParametersFromObject} from '../util/utils';
import {inject} from "@angular/core";

export abstract class AbstractBasicService<T, ID, F extends FilterType> implements BasicService<T, ID, F> {

  protected abstract readonly apiUrl: string;

  protected readonly http = inject(HttpClient);

  protected readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  findAll(page: number, size: number, filters: F, sort?: string, sortDir?: string): Observable<Page<T>> {
    let sortString;
    if (sort) {
      const sd = sortDir ? sortDir : 'asc';
      sortString = `${sort},${sd}`;
    }
    const queryParams = {...filters, page: page, size: size, sort: sortString};
    return this.http.get<Page<T>>(`${this.apiUrl}?${getParametersFromObject(queryParams)}`, this.httpOptions);
  }

  findById(id: ID): Observable<T> {
    return this.http.get<T>(`${this.apiUrl}/${id}`, this.httpOptions);
  }

}
